import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { VueMaskDirective } from 'v-mask'

Vue.directive('mask', VueMaskDirective);


Vue.config.productionTip = false;
const options = {
    // You can set your default toast options here
    timeout: 2000,
    position: "top-center",
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false,
};

Vue.use(Toast, options);
Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    render: (h) => h(App)
}).$mount("#app");