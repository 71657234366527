import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: () =>
            import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
    },
    {
        path: "/Display",
        name: "Display",
        component: () =>
            import(/* webpackChunkName: "Display" */ "../views/Display.vue"),
        props: true
    },
    {
        path: "/pinpay",
        name: "PinPay",
        component: () =>
            import(/* webpackChunkName: "PinPay" */ "../views/PinPay.vue"),
    },
    {
        path: "/PaySuccess",
        //path: "/PaySuccess/:charge_token,:ticket",
        name: "PaySuccess",
        component: () =>
            import(/* webpackChunkName: "PaySuccess" */ "../views/PaySuccess.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    // eslint-disable-next-line no-undef
    base: process.env.BASE_URL,
    routes,
});

export default router;